.btn-gerar-pdf {
    font-size: 12px !important;
}

.printable {
    width: 100%;
    max-width: 95%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    background-color: #c5c5c5;
    font-weight: bold;
    text-align: center;
}

.printable th,
.printable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.printable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.edge-specific {
    width: auto !important;
    transform: scale(0.75);
    margin-left: -15%;
}

.btn-whatsapp {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
}

.btn-whatsapp .fa-whatsapp {
    font-size: 24px;
}

.btn-whatsapp span {
    margin-left: 8px;
}

.btn-whatsapp-custom {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    padding: 5px 10px !important;
    background-color: #25D366 !important; /* Um verde mais claro do que o sucesso padrão do Bootstrap */
    border: 2px solid transparent !important; /* Remove a borda padrão */
    border-radius: 14px !important; /* Bordas arredondadas */
    color: white !important; /* Texto branco */
    font-size: 14px !important; /* Tamanho da fonte do texto */
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2) !important; /* Sombra opcional para dar um efeito elevado */
    transition: background-color 0.3s !important; /* Animação suave para o hover */
}

.btn-whatsapp-custom:hover {
    background-color: #aef7c9  !important; 
    color: black !important; 
    border: 2px solid #25D366 !important;    
}

.btn-whatsapp-custom .fa-whatsapp {
    font-size: 20px !important; /* Tamanho do ícone do WhatsApp */
}

.btn-whatsapp-custom span {
    margin-left: 8px !important; /* Espaço entre o ícone e o texto */
}
