/* Estilos para o overlay que vai aparecer quando o mouse passar por cima da imagem */
.image-zoom-overlay {
    display: flex;
    /* Escondido por padrão */
    flex-direction: column;
    position: fixed;
    /* Fixa na tela */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Fundo semi-transparente */
    z-index: 1050;
    /* Garante que o overlay fique sobre outros elementos */
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* Estilos para a imagem dentro do overlay */
.image-zoom-overlay img {
    max-width: 90%;
    /* Limita o tamanho da imagem a 80% da largura da tela */
    max-height: 90%;
    /* Limita o tamanho da imagem a 80% da altura da tela */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    /* Sombra para destacar a imagem */
    border-radius: 8px;
    /* Borda arredondada */
    margin-bottom: 20px;
}

/* Classe que será aplicada para mostrar o overlay */
.show-image-zoom-overlay {
    display: flex;
    /* Usando flexbox para centralizar a imagem */
}

.zoom-on-hover:hover {
    transform: scale(1.2);
    /* Aumentar a imagem para 150% do tamanho original ao passar o mouse */
}

.button-copy {
    margin-left: 5px;
    border-radius: 5px;
    height: 18px;
    width: 18px;
    font-size: 8px;
    background-color: white;
    text-align: center; /* Centraliza o texto horizontalmente */
    line-height: 18px; /* Centraliza o texto verticalmente */
    padding: 0; /* Remove o padding padrão para evitar deslocamento */
    border: none; /* Opcional: remove a borda do botão */  
}


.success-copied {
    margin-left: 10px;
    background-color: greenyellow;
    color: black;
}