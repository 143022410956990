.custom-nav-dropdown::after {
  display: none;
  background-color: blue;
}

.custom-navbar {
  background-color: #10006A;
}

.custom-navbar .navbar-nav .nav-link {
  color: #ffffff;
}

.custom-navbar .navbar-brand {
  color: #ffffff;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  font-weight: bold !important;
  color: #ffffff !important;
}

.custom-navbar-toggle {
  border: none !important; /* Remove a borda padrão */
}

.custom-navbar-toggle .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
  /* Isso define a cor das três linhas para branco */
}
