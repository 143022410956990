.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('./../../public/img/png/login/realist_02.png');   
    background-size: cover;
    background-position: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 340px;
}

.login-form input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.login-form button {
    padding: 10px;
    background-color: #0047b3;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.login-form button:hover {
    background-color: #003399;
}

.login-header,
.login-footer {
    text-align: center;
    margin-bottom: 15px;
}

.logo-container {
    display: flex;
    justify-content: center;
    max-width: 100%;
    margin-top: 5px;
}

.separator {
    height: 2px;
    background-color: #3E4095;
    margin-top: 25px;
}

.welcome-message {
    margin-top: 25px;
    font-size: 17px;
}

.inspweb {
    font-weight: bold;
}

.corner-image-container {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 20vw;
    max-height: 20vw;
    padding: 5px;
    background-color: #D9D9D9;
    margin: 10px;
}

.corner-image-container img {
    width: 85%;
    margin-top: 5px;
    height: auto;
}

.corner-text {
    font-size: 1.2vw;
}

/* Responsividade */

@media (max-width: 1190px) {
    .corner-text {
        font-size: 1.3vw;
    }
}

@media (max-width: 1024px) {
    .corner-text {
        font-size: 1.4vw;
    }
}

@media (max-width: 900px) {
    .corner-text {
        font-size: 1.5vw;
    }
}

@media (max-width: 768px) {
    .login-container {
        padding: 10px;
    }
    .corner-text {
        font-size: 1.6vw;
    }
}

@media (max-width: 670px) {
    .corner-text {
        font-size: 1.9vw;
    }
}

@media (max-width: 600px) {
    .corner-text {
        font-size: 2vw;
    }
}

@media (max-width: 480px) {
    .corner-text {
        font-size: 2vw;
    }
}