.table-responsive {
    overflow-x: auto;
}

.small-font-table {
    font-size: 0.7rem;
}

.table-title {
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    background-color: #538DD5 !important;
    color: white !important;
}

.green-bg {
    background-color: rgba(0, 255, 50, 0.7) !important;
}

.yellow-bg {
    background-color: rgba(255, 255, 0, 0.7) !important;
}

.red-bg {
    background-color: rgba(255, 0, 0, 0.8) !important;
}

.center-text {
    text-align: center;
    vertical-align: middle;
}

.qtde-column,
.qtde-header {
    background-color: #538DD5 !important;
    color: white !important;
}

.resultado-header {
    background-color: #538DD5 !important;
    color: white !important;
}

.thick-border-right {
    border-right: 1.5px solid black !important;
}

.thick-border {
    border: 1.5px solid black !important;
}

.last-line-white-bg {
    background-color: gray  !important;
    color: white !important;
    border-right: 1.5px solid black !important;
    font-size: 13px;
    font-weight: bold;
}
