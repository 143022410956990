.custom-edit-button {
    background-color: #010066 !important;
    border-color: #010066 !important;
}

.custom-pdf-button:hover {
    background-color: green !important;
    border-color: black !important;
    color: black !important;
}

.custom-delete-button {
    background-color: #010066 !important;
    border-color: #010066 !important;
}

.custom-permission-button {
    background-color: #010066 !important;
    border-color: #010066 !important;    
}

.custom-edit-button:hover {
    background-color: #31B0D5!important;
    border-color: black !important;
    color: black !important;
}

.custom-delete-button:hover {
    background-color: #C9302C !important;
    border-color: black !important;
    color: black !important;
}

.custom-permission-button:hover {
    background-color: #FFCA2C !important;
    border-color: black !important;
    color: black !important;
}

.custom-laudos-button {
    margin-bottom: 5px;
}

.custom-laudos-button:hover {
    background-color: transparent !important;
    border-color: black !important;
    color: black !important;
}

.custom-icon-trash-pencil {
    font-size: 20px !important;
}

.custom-icon-key {
    font-size: 24px !important;
}

.custom-icon-pdf {
    height: 25px !important;
    width: 25px !important;
}

.custom-div-table {
    height: 65vh !important;
    overflow: "auto" !important;
}

/* Fixa o cabeçalho da tabela na tela */
.thead-fix {
    background-color: #f2f2f2 !important;
    position: sticky !important;
    top: 0 !important;
    font-size: 13px;
}

.thead-fix-acessorios {
    background-color: #f2f2f2 !important;
    position: sticky !important;
    top: 0 !important;
    font-size: 18px;
}

/* Fixa o rodapé da tabela na tela */
.tfoot-fix {
    background-color: #f2f2f2;
    position: sticky;
    bottom: 0;
    font-weight: bold;
    font-size: 17px;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    /* Ajuste conforme necessário */
}
.zoom-container {
    position: fixed; /* Posicionamento fixo na tela */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050; /* Alto índice de empilhamento para ficar por cima de outros elementos */
    cursor: pointer; /* Indica que o item é clicável */
  }