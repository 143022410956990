.custom-table {
    font-size: 0.6rem;
}

.custom-table td,
.custom-table th {
    text-align: center;
    vertical-align: middle;
}

.custom-table thead th {
    position: sticky;
    top: 0;
    /* ou qualquer valor que funcione com o seu layout */
    background-color: white;
    /* para sobrescrever conteúdo ao rolar */
    z-index: 100;
    /* para assegurar que o cabeçalho está acima do conteúdo da tabela */
}

.custom-button-default-table {
    font-size: 0.6rem !important;
    width: 90%;
}

.bold-label {
    font-weight: bold;
}

.title-red-bold {
    color: red;
    font-weight: bold;
}

.custom-laudo-continue-button {
    background-color: #286090 !important;
    border-color: #286090 !important;
    color: white !important;
    width: 80% !important;
    font-size: 1.7rem !important;
    font-weight: bold !important;
    margin: auto !important;
    transition: background-color 0.3s ease, color 0.3s ease !important;
}

.custom-laudo-continue-button:hover {
    background-color: green !important;
    color: black !important;
}

.custom-laudo-send-button {
    background-color: #31B0D5 !important;
    border-color: #31B0D5 !important;
    color: black !important;
    transition: background-color 0.3s ease, color 0.3s ease !important;
}

.custom-laudo-send-button:hover {
    background-color: green !important;
}

.custom-laudo-incluir-button {
    background-color: #449D44 !important;
    border-color: #449D44 !important;
    color: white !important;
    width: 80% !important;
    font-size: 1.7rem !important;
    font-weight: bold !important;
    margin: auto !important;
    transition: background-color 0.3s ease, color 0.3s ease !important;
}

.custom-laudo-incluir-button:hover {
    background-color: green !important;
    color: black !important;
}

.uppercase-input {
    text-transform: uppercase;
}

.font-label-laudo {
    font-size: 14px !important;
    margin-bottom: -5px !important;
}

.disabled-background {
    background-color: #E9ECEF !important;
    color: #495057 !important;
}

.title-card-custom {
    font-weight: bold;
    color: white !important;
    background-image: linear-gradient(to right, #010066, #9090ca) !important;    
}

.title-card-custom button.btn-link {
    text-decoration: none; /* remove o sublinhado */
    color: inherit; /* herda a cor do elemento pai */
    font-weight: bold; /* mantém a fonte em negrito */
    padding: 0; /* remove o padding */
    background: none; /* remove o fundo */
    border: none; /* remove a borda */
}

.title-card-custom button.btn-link:hover,
.title-card-custom button.btn-link:focus {
    text-decoration: none; /* remove o sublinhado no hover e focus */
    color: inherit; /* herda a cor do elemento pai no hover e focus */
}
.card-chart-title{
    font-weight: bold !important;
    font-size: 23px !important;
}


/* styles.css */
.close-button {
    color: white;
    background: transparent;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
  }
  