.card-custom {
    height: 5.8em !important;
}

.card-title-custom {
    margin: 3%;
}

.card-text-custom {
    margin: 3%;
}

/* Outras classes que você pode precisar */
